<template>
    <card-list>

        <subheader-list-item icon="fa-align-left" title="Transaction Details"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Charge Date" label-position="inside">
                        <b-input type="text" v-model="transaction.created_at"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Amount" label-position="inside">
                        <b-input v-model="transaction.amount" placeholder="0.00"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="transaction.description"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-5">
                    <b-field>
                        <b-switch :true-value="1" :false-value="0" v-model="transaction.result">Successful</b-switch>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'TransactionForm',
        props: {
            transaction: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        data: function() {
            return {};
        },
        methods: {}
    };
</script>
