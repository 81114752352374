<template>
    <card-list>

        <subheader-list-item icon="fa-align-left" title="Item Details"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Type" label-position="inside">
                        <b-select expanded v-model="item.type">
                            <option value="installment">Installment</option>
                            <option value="deposit">Deposit</option>
                            <option value="addon">Add-On</option>
                            <option value="credit">Credit</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                <b-field label="Due On/Charge Date" label-position="inside">
                    <b-input type="date" v-model="item.due_on"/>
                </b-field>
            </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="item.title"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Quantity" label-position="inside">
                        <b-input type="number" min="1" step="1" v-model="item.quantity"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Amount" label-position="inside">
                        <b-input v-model="item.amount" placeholder="0.00"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-5">
                    <b-field>
                        <b-switch :true-value="1" :false-value="0" v-model="item.paid">Mark as Paid</b-switch>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import debounce from 'debounce';
    import {client as http} from '../../http_client';
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import {mapGetters} from "vuex";


    export default {
        name: 'PlanItemForm',
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        data: function () {
            return {

            };
        },
        computed: {
            ...mapGetters(['assignees']),
        },
        methods: {

        }
    };
</script>
