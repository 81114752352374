<template>
    <div>
        <card-header title="New Transaction" icon="fa-plus"/>

        <card-body>
            <transaction-form :transaction="newTransaction"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newTransaction.created_at || !newTransaction.description || !newTransaction.amount" @click="submit"><i class="fas fa-plus mr-3"></i>New Transaction</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";
    import TripNoteForm from "@/cards/trips/NoteForm";

    import {client as http} from '../../http_client';
    import PlanItemForm from "./PlanItemForm";
    import TransactionForm from "./TransactionForm";
    import moment from "moment";

    export default {
        props: ['card', 'props'],
        components: {TransactionForm, TripNoteForm, CardBody, CardFooter, CardHeader},
        data: function () {
            return {
                newTransaction: {
                    title: 'Payment Plan Installment',
                    amount: 0.00,
                    result: 1,
                    created_at: moment().format('YYYY-MM-DD 00:00:00')
                },
                question: {}
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/transactions/', {plan_id: this.props.planId, registration_id: this.props.regId, ...this.newTransaction, raw_result: 'Manually added.'}, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.props.planId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new transaction? Unsaved changes will be lost.');
        }
    };
</script>
